import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("circle", {
      cx: "12",
      cy: "12",
      r: "5",
      fill: "currentColor"
    }, null, -1)),
    (_openBlock(), _createBlock(_resolveDynamicComponent("style"), null, {
      default: _withCtx(() => _cache[0] || (_cache[0] = [
        _createTextVNode(" @keyframes ping { 75%, 100% { transform: scale(2); opacity: 0; } } ")
      ])),
      _: 1
    })),
    _cache[2] || (_cache[2] = _createElementVNode("circle", {
      cx: "12",
      cy: "12",
      r: "6",
      fill: "currentColor",
      style: {"animation":"ping 1s cubic-bezier(0, 0, 0.2, 1) infinite","transform-origin":"center center"}
    }, null, -1))
  ]))
}
export default { render: render }