<script setup lang="ts">
import {
	NavigationMenuTrigger,
	type NavigationMenuTriggerProps,
} from "radix-vue";
import { cn, type ClassValue } from "@/utils";
import ChevronUpIcon from "@/icons/chevron-up.svg?component";

defineProps<
	NavigationMenuTriggerProps & {
		class?: ClassValue;
	}
>();
</script>

<template>
	<NavigationMenuTrigger
		v-bind="{
			...$props,
			class: cn(
				'bg-transparent hover:bg-white/10 hover:text-white focus:bg-white/10 focus:text-white data-[active]:bg-accent/50 data-[state=open]:bg-accent/50 group inline-flex h-10 w-max items-center justify-center rounded-md px-4 py-2 text-sm font-medium transition-colors focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[state=open]:bg-white/10 data-[state=open]:text-white',
				$props.class,
			),
		}"
	>
		<slot />
		<ChevronUpIcon
			class="relative top-px ms-1 size-4 stroke-[1.5] transition-transform duration-200 ease-in [transform:rotateX(180deg)] group-data-[state=open]:[transform:rotateX(0)]"
			aria-hidden="true"
		/>
	</NavigationMenuTrigger>
</template>
