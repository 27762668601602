<script setup lang="ts">
import emblaCarouselVue from "embla-carousel-vue";
import { onMounted, ref } from "vue";
import { DialogClose } from "radix-vue";
import CloseIcon from "@/icons/close.svg?component";
import i18next from "i18next";

const t = i18next.t;

const selectedIndex = defineModel<number>({ default: 0 });

const [emblaNode, emblaApi] = emblaCarouselVue({
	align: "center",
	startIndex: selectedIndex.value,
});

const scrollSnaps = ref<number[]>([]);

const onSelect = () => {
	if (!emblaApi.value) return;
	scrollSnaps.value = emblaApi.value.scrollSnapList();
	selectedIndex.value = emblaApi.value.selectedScrollSnap();
};

onMounted(() => {
	if (emblaApi.value) {
		onSelect();
		emblaApi.value.on("reInit", onSelect);
		emblaApi.value.on("select", onSelect);
	}
});

const images = [
	"/img/showcase/showcase_client_2.webp",
	"/img/showcase/showcase_client_1.webp",
	"/img/showcase/showcase_client_3.webp",
];
</script>

<template>
	<div class="flex flex-1 flex-col items-start justify-center">
		<div class="flex flex-1 flex-col gap-8">
			<div ref="emblaNode" class="flex h-full items-center justify-center">
				<ul
					class="-me-3 flex touch-pan-y touch-pinch-zoom [backface-visibility:hidden] md:-me-8"
				>
					<li
						v-for="image in images"
						class="flex shrink-0 grow-0 basis-full items-center justify-center pe-3 md:ps-8"
					>
						<img
							@click.stop
							class="h-full max-h-[calc(70svh)] w-auto select-none rounded-lg border border-white/5 bg-white/5 object-contain shadow-lg"
							loading="lazy"
							fetchpriority="low"
							decoding="async"
							width="1920"
							height="1080"
							:src="image"
							:alt="t('landing:showcase.slider.image_alt')"
						/>
					</li>
				</ul>
			</div>
		</div>
		<div
			class="mx-auto my-4 flex h-auto w-full justify-center gap-2 sm:gap-3"
			@click.stop
		>
			<button
				class="group flex h-full overflow-hidden rounded border-2 border-white/5 opacity-50 outline-none transition-[border-color,opacity] hover:opacity-75 focus-visible:border-white/25 data-[selected]:border-white/25 data-[selected]:opacity-100"
				v-for="(_, index) in scrollSnaps"
				type="button"
				@click="emblaApi?.scrollTo(index)"
				:data-selected="index === selectedIndex ? true : undefined"
				:aria-label="
					t('landing:showcase.slider.go_to_slide', { slideNumber: index + 1 })
				"
			>
				<img
					width="128"
					height="72"
					class="h-12 w-full select-none object-contain sm:h-16"
					fetchpriority="low"
					decoding="async"
					:src="images[index]"
				/>
			</button>
		</div>
		<DialogClose
			:aria-label="t('labels.close')"
			class="absolute right-5 top-5 text-white/60 hover:text-white/75 md:right-8 md:top-8"
		>
			<CloseIcon class="size-8 stroke-2 md:size-10" />
		</DialogClose>
	</div>
</template>
